import { TableName } from "@/utils/api/trial";

export const tutorialStrategy = {
  jobSchedule: "工單自動排程",
  emergency: "急件插單",
  material: "物料計算",
  line: "換線優化",
};

export const UPLOAD_DB_TABLE_NAME = [
  "order_db",
  "resource_db",
  "inventory_db_trial",
  "wip_db_trial",
] as const;

export const REQUIRED_DB_TABLE_NAME: TableName[] = ["order_db"] as const;

export const MAX_POLLING_TIMES = 10;
export const MAX_CHECK_PREPARE_TIMES = 10;
