import { UPLOAD_DB_TABLE_NAME } from "@/constants/trial";
import { SSRParams, SSRResponse } from "@/hooks/useTableSSR";
import { RcFile } from "antd/es/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { ApiService } from "../axios";

export interface ApiSendFeedbackPayload {
  name: string;
  email: string;
  title: string;
  content: string;
  page: string;
  step: string;
  tax_id: string;
}

export interface ApiGetUploadStatusResponse {
  data: {
    last_commit: string | "N/A";
    last_validate: string | "N/A";
    source_type: string; // 來源類型
    description: string; // 來源名稱
    table_name: TableName;
    validate_status:
      | "SUCCESS"
      | "CONTENT_ERROR"
      | "MISSING_COLUMN"
      | "OVER_2000"
      | "N/A";
    error_message: string | null;
  }[];
}

interface ApiUploadSourceFilePayload {
  args: UploadRequestOption;
  tableName: TableName;
}

export type TableName = typeof UPLOAD_DB_TABLE_NAME[number];

export interface ApiUploadSourceFileResponse {
  table_name: string;
  last_commit: string; // timestamp
}

export interface ApiGetErrorTablePayload {
  table_name: TableName;
  page_size: number;
  page: number;
}

export interface ApiGetErrorTableResponse<T extends TableName> {
  data: {
    row_order: number[];
    rows: { [rowNumber: string]: InferErrorRow<T> }[];
    group_errors: {
      [col_name in keyof InferErrorRow<T>]: {
        range: [number, number];
        error_type: string;
      }[];
    }[];
    group_error_count: number;
    row_count: number;
  };
}

type ErrorRowMapping = {
  order_db: OrderErrorRow;
  resource_db: ResourceErrorRow;
  inventory_db_trial: InventoryErrorRow;
  wip_db_trial: WIPErrorRow;
};

export type InferErrorRow<T extends TableName> = ErrorRowMapping[T];

export interface OrderErrorRow {
  bom_components: string;
  mo_due: string;
  earliest_start_time: string;
  est_time: number;
  job_name: string;
  job_qty: string;
  job_sequence: number;
  lot_nbr: string;
  material_required_qty: string;
  mo_nbr: string;
  order_nbr: string;
  out_sourcing: string;
  priority: number;
  product_description: string;
  product_name: string;
  req_qty: number;
  time_unit: string;
  workcenter_name: string;
}

export interface ResourceErrorRow {
  capacity_type: string;
  job_name: string;
  line: string;
  lock_time: string;
  resource_name: string;
  resource_type: string;
  standard_capacity: string;
  workcenter_name: string;
}

export interface InventoryErrorRow {
  arrived: string;
  arrived_date: string;
  arrived_qty: number;
  is_sfg: "FALSE" | "TRUE";
  material_name: string;
  restrict_doc_nbr: string;
}

export interface WIPErrorRow {
  job_name: string;
  lot_nbr: number;
  mo_nbr: string;
  product_name: string;
  real_start_time: string;
  real_end_time: string;
  report_resource_name: string;
  sourcing: string;
  status: string;
  wip_qty: number;
  workcenter_name: string;
}

export type UpdateType = "prepare" | "schedule" | "release";

export interface PrepareStatus {
  status: "PREPARING" | "NOT_UPLOADED" | "READY" | "PREPARED";
}

export interface ScheduleStatus {
  status: "NOT_PREPARED" | "READY" | "SCHEDULING" | "SCHEDULED";
}

export interface ReleaseStatus {
  status: "NOT_RELEASED" | "RELEASED";
}

export type ApiGetUpdateTypeResponse<T extends UpdateType> = T extends "prepare"
  ? PrepareStatus
  : T extends "schedule"
  ? ScheduleStatus
  : T extends "release"
  ? ReleaseStatus
  : never;

export type SampleFileCategory =
  | "job_schedule" // Used for Tutorial 1 `GetScheduleTable`
  | "step_1_order_db" // Tutorial 1
  | "step_1_resource_db" // Tutorial 1
  | "step_1_inventory_db_trial" // Tutorial 1
  | "step_1_wip_db_trial"; // Tutorial 1

export interface GetScheduleTable_Param {
  delayOnTop: boolean;
  analysisUUID: string | undefined;
}

export interface GetScheduleTable_ExtraFilter {
  filter?: {
    job_start?: string | null;
    job_end?: string | null;
  };
}

export interface Api_GetScheduleTable_Response extends SSRResponse {
  version_id: number;
  version_timestamp: string;
  data: {
    jid: number;
    wcr_id: number;
    lid: number;
    mo_nbr: string;
    product_name: string;
    lot_nbr: string;
    workcenter_name: string;
    job_name: string;
    sourcing: string;
    job_seq_order: number;
    work_order: string;
    qty: number;
    job_sequence: number;
    priority: number;
    assigned_resources: {
      [RESOURCE_ID in string]: string;
    };
    doc_nbr: string;
    wip_qty: number | null;
    due: string;
    latest_due: string;
    plan_start_time: string;
    plan_end_time: string;
    real_start_time: string | null;
    real_end_time: string | null;
    real_resume_time: string | null;
    auto_start_time: string | null;
    auto_end_time: string | null;
    user_status: WipStatus;
    system_status: WipStatus;
    abnormal: boolean;
    has_note: boolean | null;
    latest_material_ready_date: string | null;
    material_ready_date: string | null;
    in_between: boolean | null;
    series_rule: boolean | null;
    use_extra_resource: boolean | null;
    commit_time: string | null;
    arrived_qty: number | null;
    product_description: string | null;
    job_parameter: string | null;
    item_no: string | null;
    is_locked: boolean;
    is_running: boolean;
    _rn: number;
    _rc: number;
    _delay: boolean;
  }[];
}

export interface ApiEditPreviewSchedulePayload {
  id: number;
  edit_column: "assigned_resources" | "plan_start_time" | "plan_end_time";
  /** assigned_resources for "[8, 9]" format
   *  plan_start_time for "YYYY-MM-DD HH:MM:ss" format
   *  plan_end_time for "HH:MM" format
   */
  edit_value: string;
}

export type WipStatus = "running" | "finished" | "pause" | null;

export interface ApiUndoPreviewSchedulePayload {
  version_id: number;
  wcr_id?: number;
}

export interface ApiGetResourceListResponse {
  data: {
    id: number;
    resource_name: string;
  }[];
}

const enum Endpoint {
  SendFeedback = "sendFeedback",
  GetUploadStatus = "getUploadStatus",
  UploadSourceFile = "uploadSourceFile",
  GetErrorTable = "getErrorTable",
  GetPrepareStatus = "getPrepareStatus",
  DownloadSampleFile = "downloadSampleFile",
  GetPreviewScheduleTable = "getPreviewScheduleStatus",
  GetScheduleTableFilterValues = "getScheduleTableFilterValues",
  DownloadScheduleTable = "downloadScheduleTable",
  DownloadWeeklyScheduleTable = "DownloadWeeklyScheduleTable",
  EditPreviewSchedule = "editPreviewSchedule",
  UndoPreviewSchedule = "undoPreviewSchedule",
  GetResourceList = "getResourceList",
  DownloadResultFiles = "downloadResultFiles",
}

const endpoints = {
  [Endpoint.SendFeedback]: {
    url: "/api/developer/trial-help-message",
    method: "post",
  },
  [Endpoint.GetUploadStatus]: {
    url: "/api/customer/customer-db/table",
    method: "get",
  },
  [Endpoint.UploadSourceFile]: {
    url: "/api/customer/upload-data",
    method: "post",
  },
  [Endpoint.GetErrorTable]: {
    url: "/api/customer/customer-db/error",
    method: "get",
  },
  [Endpoint.GetPrepareStatus]: {
    url: "/api/customer/flow/check",
    method: "get",
  },
  [Endpoint.DownloadSampleFile]: {
    url: "/api/export/sample-file",
    method: "get",
  },
  [Endpoint.GetPreviewScheduleTable]: {
    url: "api/wip/schedule-workcenter-result",
    method: "post",
  },
  [Endpoint.GetScheduleTableFilterValues]: {
    url: "api/wip/schedule-workcenter-result",
    method: "post",
  },
  [Endpoint.DownloadScheduleTable]: {
    url: "api/wip/schedule-workcenter-result",
    method: "post",
  },
  [Endpoint.DownloadWeeklyScheduleTable]: {
    url: "api/wip/schedule-workcenter-result",
    method: "post",
  },
  [Endpoint.EditPreviewSchedule]: {
    url: "api/wip/schedule-workcenter-result/edit",
    method: "post",
  },
  [Endpoint.GetResourceList]: {
    url: "api/resource/item/jlb",
    method: "get",
  },
  [Endpoint.UndoPreviewSchedule]: {
    url: "api/wip/schedule-workcenter-result/edit",
    method: "delete",
  },
  [Endpoint.DownloadResultFiles]: {
    url: "api/trial/export-release-result",
    method: "post",
  },
} as const;

type EndpointName = keyof typeof endpoints;

class TrialApi extends ApiService<EndpointName> {
  _endpoints = endpoints;

  sendFeedback = (payload: ApiSendFeedbackPayload) => {
    return this.send(Endpoint.SendFeedback, { data: payload });
  };

  getUploadSourceFileStatus = (tableName?: TableName) => {
    return this.send<ApiGetUploadStatusResponse>(Endpoint.GetUploadStatus, {
      params: { table_name: tableName },
    });
  };

  uploadSourceFile = ({ args, tableName }: ApiUploadSourceFilePayload) => {
    const { file } = args;

    const formData = new FormData();
    formData.append(tableName, file as Blob, (file as RcFile).name);

    return this.send<ApiUploadSourceFileResponse>(Endpoint.UploadSourceFile, {
      data: formData,
    });
  };

  getErrorTable = <T extends TableName>(payload: ApiGetErrorTablePayload) => {
    return this.send<ApiGetErrorTableResponse<T>>(Endpoint.GetErrorTable, {
      params: payload,
    });
  };

  getPrepareStatus = <T extends UpdateType>(flow: T) => {
    return this.send<ApiGetUpdateTypeResponse<T>>(Endpoint.GetPrepareStatus, {
      params: { flow },
    });
  };

  downloadSampleFile = (category: SampleFileCategory) => {
    return this.send(Endpoint.DownloadSampleFile, {
      params: { category },
      responseType: "blob",
    });
  };

  getPreviewScheduleTable = (
    data: SSRParams,
    { delayOnTop, analysisUUID }: GetScheduleTable_Param,
    extra: GetScheduleTable_ExtraFilter
  ) => {
    return this.send<Api_GetScheduleTable_Response>(
      Endpoint.GetPreviewScheduleTable,
      {
        data: { ...data, extra },
        params: { delay_on_top: delayOnTop, analysis_uuid: analysisUUID },
      }
    );
  };

  getScheduleTableFilterValues = (
    data: SSRParams,
    { analysisUUID }: GetScheduleTable_Param,
    extra: GetScheduleTable_ExtraFilter
  ) => {
    /** 避免使用相同的 `Endpoint Name` getScheduleTable 來抓取表格資料與篩選資料，
     *  預設當兩個 request 同時打出時，其中一個 request 會被 cancel。這是預期的行為，
     *  當我們有多個 request 帶上不同的 filter values 時，忽視舊的 staled request，
     *  避免 state update 到舊的資料。
     */
    return this.send<SSRResponse>(Endpoint.GetScheduleTableFilterValues, {
      data: { ...data, extra, include_data: false },
      params: { analysis_uuid: analysisUUID },
    });
  };

  editPreviewSchedule = (data: ApiEditPreviewSchedulePayload) => {
    return this.send(Endpoint.EditPreviewSchedule, { data });
  };

  undoPreviewSchedule = (data: ApiUndoPreviewSchedulePayload) => {
    return this.send(Endpoint.UndoPreviewSchedule, { data });
  };

  getResourceList = (jobName: string) => {
    return this.send<ApiGetResourceListResponse>(Endpoint.GetResourceList, {
      params: { job_name: jobName },
    });
  };

  downloadScheduleTable = (
    data: SSRParams,
    { delayOnTop, analysisUUID }: GetScheduleTable_Param,
    extra: GetScheduleTable_ExtraFilter
  ) => {
    return this.send(Endpoint.DownloadScheduleTable, {
      data: { ...data, extra, download: true },
      params: {
        delay_on_top: delayOnTop,
        analysis_uuid: analysisUUID,
      },
    });
  };

  downloadWeeklyScheduleTable = (
    data: SSRParams,
    { delayOnTop, analysisUUID }: GetScheduleTable_Param,
    extra: GetScheduleTable_ExtraFilter
  ) => {
    //! When changed, also update `getScheduleTable`
    return this.send(Endpoint.DownloadWeeklyScheduleTable, {
      data: {
        ...data,
        extra,
        // `download` and `download_7days` both need to set truth
        download: true,
        download_7days: true,
      },
      params: {
        delay_on_top: delayOnTop,
        analysis_uuid: analysisUUID,
      },
    });
  };

  downloadResultFiles = () => {
    return this.send(Endpoint.DownloadResultFiles, {
      responseType: "blob",
    });
  };
}

export default TrialApi;
