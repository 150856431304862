import useApi from "@/hooks/useApi";
import useApiState from "@/hooks/useApiState";
import TrialApi, { ApiSendFeedbackPayload } from "@/utils/api/trial";
import { Form, Input, Modal, Space, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import styled from "styled-components";

interface FeedbackModalProps {
  open: boolean;
  step: string | null;
  pageTitle: string;
  closeModal: () => void;
}

type FormValues = Omit<ApiSendFeedbackPayload, "page" | "step" | "title">;

const Text = Typography.Text;

const TargetText = styled(Text)`
  width: 100%;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.antd.colorTextSecondary};
`;

const INITIAL_VALUES: FormValues = {
  name: "",
  email: "",
  content: "",
  tax_id: "",
};

const FeedbackModal = ({
  open,
  step,
  pageTitle,
  closeModal,
}: FeedbackModalProps) => {
  const [submittable, setSubmittable] = useState<boolean>(false);

  const api = useApi(TrialApi);
  const sendFeedback = useApiState(api.sendFeedback, {
    successMessage: "送出回饋成功",
    errorMessage: "送出回饋失敗",
  });

  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch((error) => {
        const isError = error?.errorFields?.length > 0;
        if (isError) {
          setSubmittable(false);
        } else {
          setSubmittable(true);
        }
      });
  }, [formValues, form]);

  const onFinish = async (formValues: FormValues) => {
    const updatedValues = {
      ...formValues,
      title: "提問表單",
      step: step !== null ? `Step ${step}` : "",
      page: pageTitle,
    };
    const response = await sendFeedback.send(updatedValues);
    if (!response.ok) return;
    closeModal();
  };

  return (
    <Modal
      centered
      title="提問表單"
      open={open}
      onOk={form.submit}
      onCancel={closeModal}
      okButtonProps={{ disabled: !submittable }}
      okText="送出提問"
      cancelText="取消"
      maskClosable={false}
      keyboard={false}
      width={"800px"}
      styles={{ body: { padding: "1rem 0.5rem" } }}
      afterClose={() => form.resetFields()}
    >
      <Form
        form={form}
        onFinish={onFinish}
        style={{ padding: "1rem 0.5rem" }}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        initialValues={INITIAL_VALUES}
      >
        <Form.Item>
          <Space>
            <TargetText strong>提問主題：</TargetText>
            <TargetText>{pageTitle}</TargetText>
          </Space>
        </Form.Item>
        <Form.Item label="姓名" name="name" rules={[{ required: true }]}>
          <Input placeholder="請輸入您的姓名" />
        </Form.Item>
        <Form.Item label="統一編號" name="tax_id" rules={[{ required: true }]}>
          <Input placeholder="請輸入您的統一編號" />
        </Form.Item>
        <Form.Item
          label="信箱"
          name="email"
          rules={[{ required: true, type: "email" }]}
        >
          <Input placeholder="請輸入您的信箱" />
        </Form.Item>
        <Form.Item label="提問內容" name="content" rules={[{ required: true }]}>
          <TextArea
            placeholder="請輸入您的提問內容"
            autoSize={{ minRows: 5 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FeedbackModal;
