import { tutorialStrategy } from "@/constants/trial";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

interface PageState {
  page: string;
  step: string | null;
}

const usePageState = (): PageState => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { category } = useParams();

  const pathname = location.pathname;

  const pageState = {
    page: "",
    step: null,
  } as PageState;

  if (pathname === "/trial") pageState.page = "快速上線";
  else if (pathname.startsWith("/trial/tutorial")) {
    const type = category as keyof typeof tutorialStrategy;
    pageState.page = tutorialStrategy[type] ?? "";

    const step = searchParams.get("stepIndex");
    if (step) pageState.step = typeof step === "string" ? step : null;
  }

  return pageState;
};

export default usePageState;
