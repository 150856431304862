import { useCallback, useEffect, useRef, useState } from "react";

/** Hooks */
import useApiState, {
  Config as ApiStateConfig,
  InferApiState,
} from "./useApiState";

/** Plugins */
import merge from "lodash/fp/merge";
import mergeWith from "lodash/fp/mergeWith";

/** Types */
import type { TableProps } from "antd";
import type { SorterResult } from "antd/es/table/interface";
import type { Dispatch, SetStateAction } from "react";
import type { EnhancedAxiosResponse } from "../utils/axios";

/** Utils */
import { LS_TABLE_SORTING, SORTING_VERSION } from "@/constants/globals";
import { getCacheKey } from "@/utils/caching";
import { z } from "zod";
import logger from "../utils/logger";

/* ------------------- Types ------------------ */
type Api<Data, ExtraArgs extends any[]> = (
  payload: SSRParams,
  ...args: ExtraArgs
) => Promise<EnhancedAxiosResponse<Data>>;
interface UseTableSSRProps<Data, ExtraArgs extends any[]> {
  api: Api<Data, ExtraArgs>;
  apiStateConfig?: ApiStateConfig<Data>;
  customKey?: string; // for caching

  initial?: {
    pagination?: Pick<PaginationCSR, "current" | "pageSize">; // Currently not working. The first SSR request will set to 0-index by default.
    exactFilter?: ExactFilter;
    extraArgs?: ExtraArgs;
  };
  globalFilterColumns?: string[];
  exactFilterJsonColumns?: string[];
}

export interface UseTableSSRReturn<
  Data = any,
  ApiExtraArgs extends any[] = any
> {
  apiState: InferApiState<Api<Data, ApiExtraArgs>>;
  apiSend: (...args: any[]) => void;

  apiExtraArgs: ApiExtraArgs;
  setApiExtraArgs: Dispatch<SetStateAction<ApiExtraArgs>>;

  tableParams: TableParams;
  tableOnChange: (...args: any[]) => void;

  setGlobalFilter: Dispatch<SetStateAction<string>>;
  setLocalFilter: Dispatch<SetStateAction<LocalFilter>>;
  setExactFilter: Dispatch<SetStateAction<ExactFilter>>;
  ssrParams: SSRParams;

  /* -------------- Sorting Related ------------- */
  resetSorting: () => void;
  setSorterKeys: React.Dispatch<React.SetStateAction<string[]>>;

  /* ----------- Controlled Pagination ---------- */
  setPagination: (current: number, pageSize: number) => void;
}

/** For AntD Table Pagination */
interface PaginationCSR {
  current: number; // 1-indexed
  pageSize: number;
  total: number | undefined;
}

/** For server-side Table Pagination */
interface PaginationSSR {
  pageIndex: number; // 0-indexed
  pageSize: number;
}

type LocalFilter = Record<string, string>;
export type ExactFilter = Record<string, string[]>;

export interface SSRParams {
  pagination: PaginationSSR;
  sorting: {
    id: string;
    desc: boolean;
  }[];
  globalFilter: string | null;
  globalFilterColumns: string[] | null;
  localFilter: LocalFilter;
  exactFilter: ExactFilter;
  exactFilterColumns: string[] | null;
  exactFilterJsonColumns: string[] | null;
}

export interface SSRResponse {
  _: {
    global_raw_count: number;
    global_filtered_count: number;
    exact_stats?: {
      [key: string]: { count: number; value: string; total: number }[];
    };
  };
}

export interface TableParams {
  pagination: PaginationCSR;
}

/* ------------------ Default ----------------- */
/**
 * @note Partial type parameter inference is not possible. Either specify them explicitly, or let them all be inferred.
 */
const useTableSSR = <Data, ExtraArgs extends any[]>(
  props: UseTableSSRProps<Data, ExtraArgs>
): UseTableSSRReturn<Data, ExtraArgs> => {
  /* ------------------- Props ------------------ */
  const initialCurrent = props?.initial?.pagination?.current || 1;
  const initialPageSize = props?.initial?.pagination?.pageSize || 10;

  /* ------------------ States ------------------ */
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const globalFilterColumns = useRef(props.globalFilterColumns);
  const [localFilter, setLocalFilter] = useState<LocalFilter>({});
  const [exactFilter, setExactFilter] = useState<ExactFilter>(
    props?.initial?.exactFilter || {}
  );

  //! exactFilterColumns is defined dynamically
  const exactFilterJsonColumns = useRef(props.exactFilterJsonColumns);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: initialCurrent,
      pageSize: initialPageSize,
      total: undefined,
    },
    //! Do not store the function inside the state object. If really needed, watch for the staled closure!
  });

  const [ssrParams, setSsrParams] = useState<SSRParams>({
    pagination: {
      pageIndex: initialCurrent ? initialCurrent - 1 : 0,
      pageSize: initialPageSize ?? 10,
    },
    sorting: [],
    globalFilter,
    globalFilterColumns: null,
    localFilter,
    exactFilter,
    exactFilterColumns: null,
    exactFilterJsonColumns: null,
  });

  const [apiExtraArgs, setApiExtraArgs] = useState<ExtraArgs>(
    props?.initial?.extraArgs ?? ([] as any)
  );
  const apiExtraArgsRef = useRef(apiExtraArgs);

  /* -------------------------------------------- */
  /*    Listener for Global/Local/Exact Filter    */
  /* -------------------------------------------- */
  useEffect(() => {
    const cleanExactFilter: ExactFilter = {};
    Object.keys(exactFilter).forEach((key) => {
      const valueList = exactFilter[key];
      if (valueList.length > 0) {
        Object.assign(cleanExactFilter, { [key]: valueList });
      }
    });

    /** Clean up `localfilter`
     * - trim and remove empty string and undefined values.
     * ! Don't remove column name if not present in current data source. Columns may exist, but in some search results, empty data lead to empty columns.
     */
    const cleanLocalFilter: LocalFilter = {};
    Object.keys(localFilter).forEach((key) => {
      const v = localFilter[key]?.trim();
      if (v) {
        cleanLocalFilter[key] = v;
      }
    });

    setSsrParams((prev) => {
      const state = merge(prev, {
        globalFilter,
        pagination: {
          pageIndex: 0,
        },
      });

      state.localFilter = cleanLocalFilter;
      state.exactFilter = cleanExactFilter;
      state.globalFilterColumns = globalFilterColumns.current || [];
      state.exactFilterJsonColumns = exactFilterJsonColumns.current || null;

      return state;
    });

    setTableParams((prev) =>
      merge(prev, {
        pagination: {
          current: 1,
        },
      })
    );

    /** Update apiExtraArgsRef
     * @note Don't create new useEffect to update ref value. Use same useEffect so trigger above `setSsrParams` and reset pageIndex to 0.
     */
    apiExtraArgsRef.current = apiExtraArgs;
  }, [exactFilter, localFilter, globalFilter, apiExtraArgs]);

  /* -------- Update globalFilterColumns -------- */
  useEffect(() => {
    globalFilterColumns.current = props.globalFilterColumns;
  }, [props.globalFilterColumns]);

  /* ------- Update exactFilterJsonColumns ------ */
  useEffect(() => {
    exactFilterJsonColumns.current = props.exactFilterJsonColumns;
  }, [props.exactFilterJsonColumns]);

  /**
   * Sync sorting state from localStorage to ssrParams.
   */
  useEffect(() => {
    const savedSorting = getSavedSortingFromLocal(props.customKey);
    setSsrParams((prev) => ({ ...prev, sorting: savedSorting }));
  }, [props.customKey]);

  /* --------------- Control Table -------------- */
  const [sorterKeys, setSorterKeys] = useState<string[]>([]);

  const tableOnChange = useCallback<Required<TableProps<any>>["onChange"]>(
    (pagination, _filter, sorter?, extra?) => {
      logger.debug("detect table change", pagination, _filter, sorter, extra);

      /** Handle pagination
       * ? The CSR params will update immediately and data might update later due to HTTP latency. Antd throws warning if data has more rows (e.g., 20) than the page size, e.g., 10. => fix by slicing the data with page size.
       */
      const current = pagination.current || initialCurrent;
      const pageSize = pagination.pageSize || initialPageSize;

      /** Handle sorter */
      const sorting: SSRParams["sorting"] = []; // for server-side sorting
      const keys: string[] = []; // for client-side sorting (keep correct sorting order)

      if (Array.isArray(sorter)) {
        /** Multiple sorting */
        const foundSorter: {
          position: number;
          sorter: SorterResult<any>;
          name: string;
        }[] = [];
        sorter.forEach((s) => {
          if (!s.field) return;
          const sorterName = getSorterName(s);
          const sorterIndex = sorterKeys.indexOf(sorterName);
          foundSorter.push({
            position: sorterIndex === -1 ? Infinity : sorterIndex,
            sorter: s,
            name: sorterName,
          });
        });
        foundSorter.sort((a, b) => a.position - b.position);
        foundSorter.forEach((o) => {
          sorting.push({
            id: String(o.sorter.field),
            desc: o.sorter.order === "descend",
          });
          keys.push(o.name);
        });

        //* 清除不存在的 sorting keys
        setSorterKeys(keys);
      } else if (sorter?.order) {
        /** Single sorting */
        sorting.push({
          id: String(sorter.field),
          desc: sorter.order === "descend",
        });

        //* 清除不存在的 sorting keys
        const key = getSorterName(sorter);
        setSorterKeys(key ? [key] : []);
      }

      /** Update states */
      setTableParams((prev) =>
        merge(prev, { pagination: { current, pageSize } })
      );

      setSsrParams((prev) =>
        //* Use `mergeWith` to replace sorting array instead of extending it
        mergeWith((_a, b) => (Array.isArray(b) ? b : undefined), prev, {
          pagination: { pageIndex: current - 1, pageSize },
          sorting,
        })
      );

      setSavedSortingToLocal(sorting, props.customKey);
    },
    [initialCurrent, initialPageSize, sorterKeys, props.customKey]
  );

  const resetSorting = () => {
    setSsrParams((p) => ({ ...p, sorting: [] }));
    removeSavedSortingFromLocal(props.customKey);
  };

  const setPagination = useCallback((current: number, pageSize: number) => {
    setSsrParams((prev) => ({
      ...prev,
      pagination: { pageIndex: current - 1, pageSize },
    }));

    setTableParams((prev) =>
      merge(prev, { pagination: { current, pageSize } })
    );
  }, []);

  /* ------------ API with SSR Params ----------- */
  const apiState = useApiState(props.api, {
    errorMessage: "無法取得表格資料",
    ...props.apiStateConfig,
  });
  const apiSend = useCallback(async () => {
    const response = await apiState.send(
      /** Apply the latest globalFilterColumns (subjected visible columns) */
      { ...ssrParams, globalFilterColumns: globalFilterColumns.current || [] },
      ...(apiExtraArgsRef.current || [])
    );
    if (response?.ok) {
      const data = response.data as SSRResponse;

      /** Update total row counts */
      setTableParams((prev) => {
        return merge(prev, {
          pagination: {
            total: data._.global_filtered_count,
          },
        });
      });
    }
  }, [apiState, ssrParams]);

  const init = useRef(false);
  useEffect(() => {
    if (!init.current) {
      init.current = true;
      return;
    }
    /**
     * In React.StrictMode, this api may be triggered multiple times.
     * - React18 calling this effect two times (omit 1, trigger 1)
     * - `Listener for Global/Local/Exact Filter` Effect (ssrParams -> apiSend -> this Effect)
     */
    apiSend();
  }, [apiSend]);

  /* --------------- Return Object -------------- */
  return {
    apiState,
    apiSend,
    tableParams,
    tableOnChange,
    setGlobalFilter,
    setLocalFilter,
    setExactFilter,
    ssrParams,
    resetSorting,
    setSorterKeys,
    apiExtraArgs,
    setApiExtraArgs,
    setPagination,
  };
};

export default useTableSSR;

/* ------------------- Utils ------------------ */
const getSorterName = (sorter: SorterResult<any>) => {
  if (sorter.columnKey) return String(sorter.columnKey);
  if (sorter.field) return String(sorter.field);
  return "";
};

/**
 * @note Utility functions for saving and loading sorting state.
 * Get, save, or remove cached sorting state from localStorage.
 */
const getSortingCacheKey = (customKey?: string) => {
  return getCacheKey({
    prefix: LS_TABLE_SORTING,
    version: SORTING_VERSION,
    customKey,
  });
};

const getSavedSortingFromLocal = (customKey?: string): SSRParams["sorting"] => {
  try {
    const schema = z.array(z.object({ id: z.string(), desc: z.boolean() }));
    const SORTING_CACHE_KEY = getSortingCacheKey(customKey);
    const savedSorting = localStorage.getItem(SORTING_CACHE_KEY) || "[]";
    const parsedSorting = schema.safeParse(JSON.parse(savedSorting));
    return parsedSorting.success ? parsedSorting.data : [];
  } catch (e) {
    return [];
  }
};

const setSavedSortingToLocal = (
  sorting: SSRParams["sorting"],
  customKey?: string
) => {
  const SORTING_CACHE_KEY = getSortingCacheKey(customKey);
  localStorage.setItem(SORTING_CACHE_KEY, JSON.stringify(sorting));
};

const removeSavedSortingFromLocal = (customKey?: string) => {
  const SORTING_CACHE_KEY = getSortingCacheKey(customKey);
  localStorage.removeItem(SORTING_CACHE_KEY);
};
