import CopyRightFooter from "@/components/trial/CopyRightFooter";
import FloatHelper from "@/components/trial/FloatHelper";
import Header from "@/components/trial/Header/Header";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

const Container = styled.main`
  /* padding: 90px 2rem; */
  padding: 65px 0 0;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
`;

const TrialRoot: React.FC = () => {
  return (
    <Container>
      <Header />
      <Outlet />
      <CopyRightFooter />
      <FloatHelper />
    </Container>
  );
};

export default TrialRoot;
