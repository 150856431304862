import { useState } from "react";

const useFeedback = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [targetText, setTargetText] = useState<string>("");
  const [step, setStep] = useState<string | null>(null);

  const openModalWith = (page: string, step?: string | null) => {
    setTargetText(page);
    setStep(step ?? null);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return {
    open,
    openModalWith,
    closeModal,
    targetText,
    step,
  };
};

export default useFeedback;
