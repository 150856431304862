import i18n from "i18next";

/** i18next Plugins */
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

/** Translation JSON */
import en__component from "@/assets/i18n/en-US/component.json";
import en__workcenter from "@/assets/i18n/en-US/workcenter.json";
import en__common from "../assets/i18n/en-US/common.json";
import en__dashboard from "../assets/i18n/en-US/dashboard.json";
import en__data from "../assets/i18n/en-US/dataManager.json";
import en__gantt from "../assets/i18n/en-US/gantt.json";
import en__material from "../assets/i18n/en-US/material.json";
import en__process from "../assets/i18n/en-US/process.json";
import en__project from "../assets/i18n/en-US/project.json";
import en__report from "../assets/i18n/en-US/report.json";
import en__resources from "../assets/i18n/en-US/resources.json";
import en__setting from "../assets/i18n/en-US/setting.json";
import en__versions from "../assets/i18n/en-US/versions.json";

/** Types */
import { LANG } from "../types/i18n";

/** Constants */
import { LS_LANG_LOGIN } from "../constants/globals";

const bundledResources = {
  [LANG.EN_US]: {
    common: en__common,
    setting: en__setting,
    dashboard: en__dashboard,
    report: en__report,
    versions: en__versions,
    project: en__project,
    resources: en__resources,
    workcenter: en__workcenter,
    component: en__component,
    gantt: en__gantt,
    process: en__process,
    data: en__data,
    material: en__material,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(ChainedBackend)
  .init({
    /**
     * @param lowerCaseLng change locale to lowercase, e.g., en-US => en-us
     * @param cleanCode change language to lowercase, e.g., EN => en
     * @note bundle en to local file (offline available)
     * ! Specified `lng` property will overwrite languagedetector.
     *
     * * lng, fallbackLng 的語言一定會被加載
     */
    debug: false,

    lng: LANG.ZH_TW,
    fallbackLng: LANG.EN_US,
    load: "currentOnly",

    ns: ["common"],
    defaultNS: "common",

    interpolation: {
      escapeValue: false,
    },

    lowerCaseLng: false,
    cleanCode: true,

    /** i18next-chained-backend + i18next-http-backend */
    backend: {
      backends: [HttpBackend, resourcesToBackend(bundledResources)],
      backendOptions: [
        {
          loadPath: `${process.env.REACT_APP_I18N_URL}/{{lng}}/{{ns}}.json`,

          // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
          queryStringParams: { v: "20241114" },
        },
      ],
    },

    /** i18next-browser-languagedetector */
    detection: {
      order: [
        // "querystring",
        "localStorage",
      ],
      // lookupQuerystring: "lng",
      lookupLocalStorage: "i18nextLng",
    },
  });

export default i18n;

/* ---------- Clear Login Page Locale --------- */
window.localStorage.removeItem(LS_LANG_LOGIN);
