import { PreferenceHook } from "@/components/ui/Preference";
import { CustomColumnType } from "@/components/ui/ResizableTable";
import XCellMore from "@/components/ui/table/XCellMore";
import XCellTag from "@/components/ui/table/XCellTag";
import {
  RESOURCE_TYPE_COLOR,
  TABLE_TAG_TEXT_BASE_COLOR,
  THEME_PALETTE_LIGHT,
} from "@/constants/globals";
import { SSRParams } from "@/hooks/useTableSSR";
import { Api_GetResourceTable_Response } from "@/utils/api/resource";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { RefObject } from "react";
import { TFunction } from "react-i18next";
import { Link } from "react-router-dom";
import JobTags from "../Table/JobTags";
import DeleteModal from "./DeleteModal";
import { ColumnType as ResourceColumnType } from "./ResourceListContext.type";

type UseTranslationT = TFunction<"resources", "_pages.management.topBar">;

interface ExactFilter {
  value: string;
  label: string;
  isJson?: boolean;
}

const generatePreferenceHeaders = (
  t: UseTranslationT
): PreferenceHook["columns"] => {
  return [
    {
      key: "id",
      label: t("colName.rId"),
      disableToggleFix: true,
    },
    {
      key: "resource_name",
      label: t("colName.resourceName"),
      disableToggleFix: true,
    },
    {
      key: "workcenter_name",
      label: t("colName.workcenterName"),
    },
    {
      key: "line",
      label: t("colName.line"),
    },
    {
      key: "resource_type",
      label: t("colName.resourceType"),
    },
    {
      key: "job_name",
      label: t("colName.capability"),
    },
    {
      key: "capacity_type",
      label: t("colName.capacityType"),
    },
    {
      key: "standard_capacity",
      label: t("colName.capacity"),
    },
    {
      key: "line_capacity",
      label: t("colName.lineCapacity"),
    },
    {
      key: "calendar_name",
      label: t("colName.defaultWorkingDays"),
    },
    {
      key: "hours_name",
      label: t("colName.defaultWorkingHours"),
    },
    {
      key: "disabled",
      label: t("colName.disabled"),
    },
    {
      key: "more",
      label: t("table.more"),
      disableToggleFix: true,
    },
  ];
};

const generateI18nHeader = (headers: PreferenceHook["columns"]) => {
  return headers.reduce((result, header) => {
    result[header.key] = header.label;
    return result;
  }, {} as Record<string, string>);
};

const generateExactFilterColumns = (t: UseTranslationT): ExactFilter[] => {
  return [
    {
      value: "workcenter_name",
      label: t("colName.workcenterName"),
      isJson: true,
    },
    {
      value: "line",
      label: t("colName.line"),
      isJson: true,
    },
    {
      value: "resource_type",
      label: t("colName.resourceType"),
    },
    {
      value: "job_name",
      label: t("colName.capability"),
      isJson: true,
    },
    {
      value: "capacity_type",
      label: t("colName.capacityType"),
    },
    {
      value: "calendar_name",
      label: t("colName.defaultWorkingDays"),
    },
    {
      value: "hours_name",
      label: t("colName.defaultWorkingHours"),
    },
    {
      value: "disabled",
      label: t("colName.disabled"),
    },
  ];
};

const generateExactFilterJsonColumns = (
  exactFilterColumns: ExactFilter[]
): string[] => {
  return exactFilterColumns.filter((i) => i.isJson).map((i) => i.value);
};

const generateGlobalFilterColumns = (
  columns: ResourceColumnType[]
): string[] => {
  return columns.reduce((prev: string[], curr) => {
    if (curr.dataIndex) prev.push(curr.dataIndex as string);
    return prev;
  }, []);
};

const generateMoreTag = (
  _: string,
  record: Api_GetResourceTable_Response,
  t: UseTranslationT,
  setId: (id: number | undefined) => void,
  { editRef, deleteRef }: { editRef: Ref; deleteRef: Ref },
  isTrial: boolean
) => {
  const items: ItemType[] = [
    {
      key: "viewResource",
      label: (
        <>
          {isTrial ? (
            <span
              onClick={() => {
                const prefix = isTrial ? "/trial" : "";
                window.open(
                  `${process.env.PUBLIC_URL}${prefix}/resource/${record.id}`,
                  "_blank"
                );
              }}
            >
              {t("table.view")}
            </span>
          ) : (
            <Link to={`/resource/${record.id}`}>{t("table.view")}</Link>
          )}
        </>
      ),
      icon: <EyeOutlined />,
    },
    {
      key: "editResource",
      label: t("table.edit"),
      icon: <EditOutlined />,
      onClick: () => {
        setId(record.id);
        editRef.current?.click();
      },
    },
    {
      key: "deleteResource",
      label: <DeleteModal record={record} ref={deleteRef} />,
      danger: true,
      icon: <DeleteOutlined />,
      onClick: () => deleteRef.current?.click(),
    },
  ];

  return <XCellMore items={items} />;
};

type Ref = RefObject<HTMLElement>;

const generateTableColumns = (
  t: UseTranslationT,
  wrap: boolean,
  setId: (id: number | undefined) => void,
  ref: { editRef: Ref; deleteRef: Ref },
  isTrial: boolean
): ResourceColumnType[] => {
  return [
    {
      title: t("colName.rId"),
      dataIndex: "id",
      width: 70,
      fixed: "left",
    },
    {
      title: t("colName.resourceName"),
      dataIndex: "resource_name",
      width: 200,
      fixed: "left",
    },
    {
      title: t("colName.workcenterName"),
      dataIndex: "workcenter_name",
      width: 200,
      render: (value) => <XCellTag value={value} wrap={wrap} />,
    },
    {
      title: t("colName.line"),
      dataIndex: "line",
      width: 100,
      render: (value) => {
        return <XCellTag value={value} wrap={wrap} />;
      },
    },
    {
      title: t("colName.resourceType"),
      dataIndex: "resource_type",
      render: (value) => {
        return (
          <XCellTag
            value={value}
            config={{
              colors: RESOURCE_TYPE_COLOR,
              textColor: TABLE_TAG_TEXT_BASE_COLOR,
              textCovert: { [value]: t(`exactFilter.resource_type.${value}`) },
            }}
          />
        );
      },
    },
    {
      title: t("colName.capability"),
      dataIndex: "job_name",
      render: (value, record) => (
        <JobTags
          value={value}
          resourceName={record.resource_name}
          wrap={wrap}
        />
      ),
    },
    {
      title: t("colName.capacityType"),
      dataIndex: "capacity_type",
      width: 100,
    },
    {
      title: t("colName.capacity"),
      dataIndex: "standard_capacity",
      width: 170,
      render: (value) =>
        value === -1 ? (
          <XCellTag
            value={t("table.unlimited")}
            config={{ baseBgColor: THEME_PALETTE_LIGHT["warning"] }}
          />
        ) : (
          value
        ),
    },
    {
      title: t("colName.lineCapacity"),
      dataIndex: "line_capacity",
      width: 100,
    },
    {
      title: t("colName.defaultWorkingDays"),
      dataIndex: "calendar_name",
    },
    {
      title: t("colName.defaultWorkingHours"),
      dataIndex: "hours_name",
    },
    {
      title: t("colName.disabled"),
      dataIndex: "disabled",
      render: (value) =>
        value === true && (
          <MinusCircleOutlined style={{ color: "red", fontSize: "1rem" }} />
        ),
    },
    {
      title: t("table.more"),
      key: "more",
      render: (_, record) => generateMoreTag(_, record, t, setId, ref, isTrial),
      fixed: "right",
    },
  ];
};

function applyWrapping(o: ResourceColumnType, wrap: boolean) {
  if (!wrap && !["more"].includes(o.key as string)) o.ellipsis = true;
}

function applySorting(o: ResourceColumnType, ssrParams: SSRParams) {
  if (
    o.dataIndex &&
    ![
      "required_resources",
      "primary_resources",
      "secondary_resources",
    ].includes(o.dataIndex as string)
  ) {
    const sorter = ssrParams.sorting.find((s) => s.id === o.dataIndex);
    o.sorter = {
      multiple: 1,
    };
    o.sortOrder = sorter ? (sorter.desc ? "descend" : "ascend") : null;
  }
}

function allowDraggable(o: CustomColumnType<any>) {
  /** 允許拖曳 */
  o.draggable = true;
}

export {
  allowDraggable,
  applySorting,
  applyWrapping,
  generateExactFilterColumns,
  generateExactFilterJsonColumns,
  generateGlobalFilterColumns,
  generateI18nHeader,
  generatePreferenceHeaders,
  generateTableColumns,
};
