import { Typography } from "antd";
import React from "react";
import styled from "styled-components";

const Text = Typography.Text;

const Footer = styled.footer`
  border-top: 1px solid var(--bs-light);
  background-color: ${({ theme }) => (theme.dark ? "#293042" : "#eceff4")};
  font-size: 0.8rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`;

const CopyRightText = styled(Text)`
  margin: 0;
  position: absolute;
  bottom: 0.5rem;
  right: calc(50vw - 6.5rem);
`;

const CopyRightFooter: React.FC = () => {
  return (
    <Footer>
      <CopyRightText>
        &copy; {new Date().getFullYear()} -{" "}
        <span className="text-muted">InterAgent Co., Ltd.</span>
      </CopyRightText>
    </Footer>
  );
};

export default CopyRightFooter;
