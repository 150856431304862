import { LS_TABLE_WIDTH, WIDTH_VERSION } from "@/constants/globals";
import { SSRParams } from "@/hooks/useTableSSR";
import { NotNull } from "@/types/util";
import { getCacheKey as getLocalStorageCacheKey } from "@/utils/caching";
import logger from "@/utils/logger";
import { Table } from "antd";
import type { ColumnType, TableProps } from "antd/es/table";
import merge from "lodash/fp/merge";
import throttle from "lodash/throttle";
import type { Reference } from "rc-table/lib";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDragListView from "react-drag-listview";
import type { ResizeCallbackData } from "react-resizable";
import { Resizable } from "react-resizable";
import styled from "styled-components";
import { z } from "zod";
import { PreferenceValues } from "./Preference";
import useDraggable, { DraggableHook } from "./antdTableExtension/useDraggable";

/* -------------------- Zod ------------------- */
const cacheWidthSchema = z
  .record(
    z.string(),
    z.object({
      width: z.number(),
    })
  )
  .nullable();

/* ------------------- Types ------------------ */
interface ResizableTableProps extends Omit<TableProps<any>, "columns"> {
  tableProps: Omit<TableProps<any>, "columns">;
  hook: UseResizableTableHook;
  minWidth?: number;
  preference?: PreferenceValues;
}

export interface UseResizableTableHook {
  columns: CustomColumnType[];
  setColumns: React.Dispatch<React.SetStateAction<CustomColumnType[]>>;
  cacheColumn: React.MutableRefObject<CacheColumn>;
  initColumnWidth: (
    columns: CustomColumnType<any>,
    config?: { reset?: boolean }
  ) => CustomColumnType<any>;
  updateCacheColumn: (colKey: string, width: number) => void;
  getCacheColumnWidth: (key: string) => number | undefined;
  resetColumnWidth: () => void;
  disabledColList?: string[]; // disable column resizing

  draggableHook: DraggableHook;
  resetDraggableColumn: () => void;

  ref: React.MutableRefObject<Reference | null>;
}

type CacheColumn = NotNull<z.infer<typeof cacheWidthSchema>>;

export type CustomColumnType<T = any> = ColumnType<T> & {
  /** 非零整數，用於釘選欄位排序
   * Fix Left: 3, 2, 1 ... 正數，大的在左邊
   * Fix Right: -1, -2, -3, ... 負數，小的在右邊
   */
  customFixed?: number;
  draggable?: boolean;
};

type ResizableTableThProps = React.HTMLAttributes<any> & {
  onResize: (
    e: React.SyntheticEvent<Element>,
    data: ResizeCallbackData
  ) => void;
  nonDOMProps?: {
    width: number;
    fixed: CustomColumnType["fixed"];
    isPrevColumnFixed: boolean;
    isNextColumnFixed: boolean;
  };
};

/* ------------------ Default ----------------- */
const ResizableTable = (props: ResizableTableProps) => {
  const { hook, minWidth, tableProps, preference } = props;

  const enableResizable = preference?.resizable ?? true;
  const enableDraggable = preference?.draggable ?? false;

  const {
    columns,
    setColumns,
    disabledColList,
    updateCacheColumn,
    getCacheColumnWidth,
    draggableHook,
    ref,
  } = hook;

  const sortedColumns: CustomColumnType[] = [...columns]
    .sort(draggableHook.sortDraggable)
    .sort(sortFixedColumn);

  /** Resize by update column state
   * @con the column state update will trigger many side effects, which lead to poor performance and bad UX.
   * !@note Don't delete this method in case NativeJS method failed on ANTD package update.
   */
  // const throttleResizeByReactJS = useRef(
  //   throttle(
  //     (index, size: ResizeCallbackData["size"], originalWidth: number) => {
  //       setColumns((prev) => {
  //         const newColumns = [...prev];
  //         newColumns[index] = {
  //           ...newColumns[index],
  //           width: size.width,
  //         };
  //         /** Update width cache */
  //         const colKey = getCacheKey(newColumns[index]);
  //         updateCacheColumn(colKey, size.width);

  //         /** Scroll along to expand `fixed right` column intuitively */
  //         if (newColumns[index].fixed === "right") {
  //           let wrapperNode = ref.current?.nativeElement; // class "ant-table-wrapper"
  //           let bodyNode = wrapperNode?.querySelector(".ant-table-body");
  //           if (bodyNode) {
  //             bodyNode.scrollLeft =
  //               bodyNode.scrollLeft + size.width - originalWidth;
  //           }
  //         }
  //         return newColumns;
  //       });
  //     },
  //     20
  //   )
  // );

  /** Resize by directly manipulate DOM element style attribute
   * @pro Comparable performance boost
   * @con The className update by future ANTD version may invalidate this function. The column state keep a stale width attribute.
   * @note In Chrome, updating the <col> in the body resizes both header and body. Updating header <col> only affects the header. Simultaneously updating body and header <col> causes strange width jumping, especially when content isn't scrollable. To avoid issues, updating only the body <col> is recommended.
   * @note When turn on `sticky: offsetHeader`. The table structure changes to 2 parts `ant-table-body` and `ant-table-header`. Otherwise, the table consists of 1 part `ant-table-content`.
   */
  const throttleResizeByNativeJS = throttle(
    (
      index,
      size: ResizeCallbackData["size"],
      _currWidth: number,
      prefixColCount: number = 0
    ) => {
      const width = size.width;
      const nodeIndex = index + 1 + prefixColCount;

      let wrapperNode = ref.current?.nativeElement; // class "ant-table-wrapper"
      if (!wrapperNode) return;

      /** Update UI width by CSS */
      const bodyColNode: HTMLElement | null = wrapperNode.querySelector(
        `.ant-table-body colgroup col:nth-child(${nodeIndex})`
      );
      // const headerColNode: HTMLElement | null = wrapperNode.querySelector(
      //   `.ant-table-header colgroup col:nth-child(${nodeIndex})`
      // );
      const widthPixel = `${width}px`;
      if (bodyColNode) {
        bodyColNode.style["width"] = widthPixel;
      } else {
        const contentColNode: HTMLElement | null = wrapperNode.querySelector(
          `.ant-table-content colgroup col:nth-child(${nodeIndex})`
        );
        if (!contentColNode) return;
        contentColNode.style["width"] = widthPixel;
      }

      /** Update width cache */
      const colKey = getCacheKey(sortedColumns[index]);
      updateCacheColumn(colKey, width);
    },
    10
  );

  /** Resize callback for plugin `react-resizable` */
  const handleResize =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const currWidth = Number(
        getCacheColumnWidth(getCacheKey(sortedColumns[index]))
      );

      if (
        (typeof enableResizable === "boolean" && !enableResizable) ||
        (typeof minWidth === "number" &&
          size.width < minWidth &&
          size.width <= currWidth) // allow resizing up if already < minWidth
      )
        return;

      throttleResizeByNativeJS(
        index,
        size,
        currWidth,
        tableProps.rowSelection ? 1 : 0
      );
    };

  /** Extend column definition
   * @note Need to sort first to find the correct column index. If not, it may resize wrong column due to incorrect index.
   * @note Do not sort inplace (mutate state)
   */
  const extColumns: CustomColumnType[] = sortedColumns.map((column, index) => {
    const newCol = {
      ...column,

      /** (必要步驟) 從 CacheColumn 取得最新 width，因為效能優化關係，以下
       * 1. resizeByNativeJS
       * 2. resetColumnWidth (應可以同步更新 column state 中的 width，因為需要刷新 UI)
       * 僅更新 cacheColumn，不更新 columns state，所以 width 仍是舊數值
       */
      width: getCacheColumnWidth(getCacheKey(column)) || column.width,
    };

    if (enableDraggable && column.draggable) {
      newCol.title = (
        <SDraggableTitle
          className={"dragHandler"}
          onClick={(e) => e.stopPropagation()}
        >
          {newCol.title}
        </SDraggableTitle>
      );
    }

    if (
      enableResizable &&
      !disabledColList?.includes(String(column.dataIndex || column.key))
    ) {
      newCol.onHeaderCell = (): ResizableTableThProps => {
        /** 繼承已經被定義的 `onHeaderCell` 參數，位於 Return 數值 */
        const prevObj =
          (column.onHeaderCell && column.onHeaderCell(column as any)) || {};

        const prevColumn = sortedColumns[index - 1];
        const nextColumn = sortedColumns[index + 1];

        /** 上一個欄位是否為固定欄位 */
        let isPrevColumnFixed = false;
        if (prevColumn && prevColumn.fixed) {
          isPrevColumnFixed = true;
        }

        /** 下一個欄位是否為固定欄位 */
        let isNextColumnFixed = false;
        if (nextColumn && nextColumn.fixed) {
          isNextColumnFixed = true;
        }

        return {
          ...prevObj,
          onResize: handleResize(index) as React.ReactEventHandler<any>,

          /** 注意 stale closure：不要傳靜態 width，使用 refObject 取得最新寬度 */
          nonDOMProps: {
            width:
              getCacheColumnWidth(getCacheKey(column)) || Number(column.width),
            fixed: column.fixed,
            isNextColumnFixed,
            isPrevColumnFixed,
          },
        };
      };
    }

    return newCol;
  });

  /** Reorder Columns by dragging */
  const onDragEnd = (fromIdx: number, toIdx: number) => {
    if (!enableDraggable) return;

    /**
     * 當有設定Row Selection時，畫面上的第一欄位為 checkbox，因此更新 columns 時需將其排除在外
     */
    if (tableProps.rowSelection) {
      fromIdx--;
      toIdx--;
    }

    /** Row Selection Column 沒有 Column 設定 */
    if (!extColumns[toIdx] || !extColumns[fromIdx]) return;

    /** 不允許拖曳到 Fixed Column 中間
     * @optimize 拖曳進到 Fixed Column 中，變更目前 Column 為 Fixed
     */
    if (extColumns[toIdx].fixed || extColumns[toIdx].customFixed) return;

    /** 不允許拖曳 Fixed Column
     * @optimize fixed column 可互相拖曳。拖出解除 Fixed 狀態
     */
    if (extColumns[fromIdx].fixed || extColumns[fromIdx].customFixed) return;

    /** Create new state array */
    const newState = [...extColumns];

    /** Keep column width */
    // newState.forEach((column) => {
    //   column.width = getCacheColumnWidth(getCacheKey(column)) || column.width;
    // });

    /**
     * Remove the item at the fromIdx position and store it in the `item` variable
     */
    const item = newState.splice(fromIdx, 1)[0];

    /**
     * Insert the `item` at the toIdx position
     */
    newState.splice(toIdx, 0, item);

    /** Memo drggable order */
    draggableHook.updateDraggable(newState);

    /** Trigger rendering */
    setColumns((p) => [...p]);
  };

  return (
    <ReactDragListView.DragColumn
      onDragEnd={onDragEnd}
      nodeSelector="th"
      handleSelector=".dragHandler"
    >
      <Table
        ref={ref}
        {...tableProps}
        columns={extColumns}
        components={{
          header: { cell: enableResizable ? ResizableTitle : undefined },
        }}
      />
    </ReactDragListView.DragColumn>
  );
};

export default ResizableTable;

/* ------------------- Hooks ------------------ */
/** @param {string[]} disabledColList - 欲停用 Col Resize 的 column dataIndex 或 key */

export const useResizableTable = ({
  widthFactor_ASCII,
  widthFactor_Non_ASCII,
  disabledColList,
}: {
  widthFactor_ASCII?: number;
  widthFactor_Non_ASCII?: number;
  disabledColList?: string[];
} = {}): UseResizableTableHook => {
  const [columns, setColumns] = useState<any[]>([]);

  const defaultColumnWidth = useRef<CacheColumn>({});

  const getUserDefinedColumnWidth = (colKey: string) => {
    if (!defaultColumnWidth.current) return;
    return defaultColumnWidth.current[colKey]?.width;
  };

  const cacheColumn = useRef<CacheColumn>({});
  const cacheKey = getLocalStorageCacheKey({
    prefix: LS_TABLE_WIDTH,
    version: WIDTH_VERSION,
  });

  useEffect(() => {
    try {
      const cacheData = cacheWidthSchema.parse(
        JSON.parse(String(localStorage.getItem(cacheKey)))
      );
      cacheColumn.current = cacheData || {};
    } catch (e) {
      logger.error(e);
    }
  }, [cacheKey]);

  const persistCacheColumn = useRef(
    throttle(
      () => localStorage.setItem(cacheKey, JSON.stringify(cacheColumn.current)),
      250,
      { leading: false }
    )
  );

  const updateCacheColumn = (colKey: string, width: number) => {
    cacheColumn.current = merge(cacheColumn.current, {
      [colKey]: { width },
    });
    persistCacheColumn.current();
  };

  const getCacheColumnWidth = (colKey: string) =>
    cacheColumn.current[colKey]?.width;

  const initColumnWidth = useCallback<UseResizableTableHook["initColumnWidth"]>(
    (column, config): CustomColumnType => {
      /**
       * ! When using Resizable Header, width is required. If not specified, the header width will be incorrect and disappear in next column render.
       *
       * Use the width in following order:
       * (1) cache width (current width)
       * (2) width defined in the columns
       * (3) width determined from the title length
       *
       * * Use `key` to cache the width if possible. Otherwise, `title` is the second choice. Never use `dataIndex` because custom columns has null value.
       */
      const cacheKey = getCacheKey(column);

      const guessWidthFromTitle = (text: string) => {
        const ascii_count = text.match(/[ -~]/gi)?.length ?? 0;
        const non_ascii_count = text.length - ascii_count;

        const ascii_factor = widthFactor_ASCII ?? 15;
        const non_ascii_factor = widthFactor_Non_ASCII ?? 30;

        return ascii_count * ascii_factor + non_ascii_count * non_ascii_factor;
      };

      const initialWidth = getUserDefinedColumnWidth(cacheKey);
      const currentWidth = getCacheColumnWidth(cacheKey);
      const defaultWidth = column?.width;
      const inferWidth = guessWidthFromTitle(String(column?.title));

      let width;
      if (config?.reset) {
        width = initialWidth ?? inferWidth;
      } else {
        width = currentWidth ?? defaultWidth ?? inferWidth;
      }
      updateCacheColumn(cacheKey, Number(width));

      /** Memo the user defined width if not record */
      if (!defaultColumnWidth.current[cacheKey]) {
        defaultColumnWidth.current[cacheKey] = {
          width: Number(defaultWidth ?? inferWidth),
        };
      }

      return {
        ...column,
        width,
      };
    },
    [widthFactor_ASCII, widthFactor_Non_ASCII]
  );

  const resetColumnWidth = () => {
    columns.forEach((column) => initColumnWidth(column, { reset: true }));
    setColumns((prev) => [...prev]);
  };

  /* ----------------- Draggable ---------------- */
  const draggableHook = useDraggable();
  const { updateDraggable, resetDraggable } = draggableHook;
  const resetDraggableColumn = () => {
    resetDraggable();
    updateDraggable(columns);
    setColumns((prev) => [...prev]);
  };

  /* ----------------- Table Ref ---------------- */
  const ref = useRef<Reference | null>(null);

  return {
    columns,
    setColumns,
    cacheColumn,
    initColumnWidth,
    updateCacheColumn,
    getCacheColumnWidth,
    resetColumnWidth,
    disabledColList,
    draggableHook,
    resetDraggableColumn,
    ref,
  };
};

/* --------------- Resizable TH --------------- */

const ResizableTitle = (props: ResizableTableThProps) => {
  const [offset, setOffset] = useState<string | number>("auto");
  const { onResize, nonDOMProps, ...restProps } = props;
  const { width, fixed, isNextColumnFixed } = nonDOMProps || {};

  if (!width) {
    return <th {...restProps} />;
  }

  const getHandlerOutsidePixel = () => {
    if (fixed) return 0;
    if (!fixed && isNextColumnFixed) return 0;
    return -4;
  };

  return (
    <Resizable
      width={width}
      height={0}
      handle={(h, ref) => {
        /**
         * @note 當下一個欄位是 Fixed (Fixed Right)，將 handler z-index 設定高一階，不使用 handlerOutsidePixel。意思是，目前欄位的 handelr 不超出 Th 範圍，且 Layer 高於下一個欄位 handler。
         */

        const isRight = h === "e";
        return (
          <SHandler
            ref={ref}
            style={{
              ...{
                height: "100%",
                position: "absolute",
                top: 0,
                cursor: "col-resize",
                zIndex: isNextColumnFixed ? 2 : 1,
              },
              ...(isRight
                ? {
                    borderRight: "8px solid #fff0",
                    left: offset,
                    right: getHandlerOutsidePixel(),
                  }
                : {
                    borderLeft: "8px solid #fff0",
                    left: getHandlerOutsidePixel(),
                    right: offset,
                  }),
            }}
            onClick={(e) => {
              /**
               * ! Required. Stop click event being propagated to <th> which will trigger sorting.
               */
              e.stopPropagation();
            }}
          />
        );
      }}
      onResize={onResize}
      /**
       * * [TEMP. FIX] fill the handler to <th> to prevent onClick drop outside handler which will trigger sorting.
       */
      onResizeStart={() => setOffset(0)}
      onResizeStop={() => setOffset("auto")}
      draggableOpts={{
        enableUserSelectHack: false,
      }}
      resizeHandles={[fixed === "right" ? "w" : "e"]}
      axis="x"
    >
      <th
        {...restProps}
        style={{
          ...restProps.style,
          ...{
            /** 設置 overflow 允許跨欄位的 resizable handle 顯示 */
            overflow: "visible",
          },
        }}
      />
    </Resizable>
  );
};

const SHandler = styled.span`
  &:hover {
    border-color: ${(prop) => prop.theme.antd.colorInfoBgHover} !important;
  }
`;

/* ------------------- Utils ------------------ */
export const getCacheKey = (column: CustomColumnType) => {
  return String(column.key || column.dataIndex || column.title);
};

const getFixedColumnNumber = (c: CustomColumnType): number => {
  const { fixed, customFixed } = c;

  if (customFixed) return customFixed;
  if (fixed) {
    const isLeft = fixed === true || fixed === "left";
    return isLeft ? Infinity : -Infinity;
  }
  return 0;
};

const sortFixedColumn = (a: CustomColumnType, b: CustomColumnType) => {
  const fixedA = getFixedColumnNumber(a);
  const fixedB = getFixedColumnNumber(b);
  return fixedA > fixedB ? -1 : fixedA === fixedB ? 0 : 1;
};

export const applyWrap = (o: any, wrap: boolean): void => {
  if (!wrap && !["execute", "more"].includes(o.key as string)) {
    o.ellipsis = true;
  }
};

export const applySorting = (o: any, ssrParams: SSRParams): void => {
  if (o.dataIndex) {
    const sorter = ssrParams.sorting.find((v) => v.id === o.dataIndex);
    o.sorter = { multiple: 1 };
    o.sortOrder = "descend";
    o.sortOrder = sorter ? (sorter.desc ? "descend" : "ascend") : null;
  }
};

export const applyDraggable = (o: any): void => {
  o.draggable = true;
};

/* ------------- Styled-Component ------------- */
const SDraggableTitle = styled.div`
  cursor: move;
  &:hover {
    background-color: #d9a15346;
  }
`;
