import { useEffect, useState } from "react";

/** Components */
import FunctionButton from "./FunctionButton";
import Menu from "./Menu";
import MobileMenu from "./MobileMenu";
import NotifAbnormalMessage from "./Notification/NotifAbnormal";
import NotifSaraMessage from "./Notification/NotifSaraMessage";
import ProgressBar from "./ProgressBar";
import Shortcut from "./Shortcut";
import UserAccount from "./UserAcount";

/** Constants */
import {
  ID_TOUR_HEADER_ACTION,
  ID_TOUR_HEADER_MENU,
  ID_TOUR_HEADER_NOTIF,
  ID_TOUR_HEADER_PROFILE,
  ID_TOUR_HEADER_SHORTCUT,
  LS_SHOW_TOUR,
} from "../../constants/globals";

/** Hooks */
import useAuth from "@/hooks/useAuth";
import useLocalStorage from "../../hooks/useLocalStorage";
import useMedia from "../../hooks/useMedia";

/** Icons & Images */
import { FiMenu } from "react-icons/fi";
import logoIcon from "../../assets/images/logo_icon.svg";
import logoText from "../../assets/images/logo_text.svg";

/** Plugins */
import { Tour } from "antd";
import styled from "styled-components";

/** ---------- Code Start ---------- */
const Header = ({
  toggleSidebar,
  noSidebar,
}: {
  toggleSidebar: () => void;
  noSidebar: boolean;
}) => {
  const { md } = useMedia(); //(min-width: 768px)
  const { isAdmin } = useAuth();
  /** Tours */
  const [showTourLS, setShowTourLS] = useLocalStorage(LS_SHOW_TOUR, true);
  const [showTour, setShowTour] = useState(md && showTourLS);
  useEffect(() => {
    /** Hide on < md screen */
    !md && setShowTour(false);
  }, [md]);

  const availableItems = headerItems.filter((item) => {
    return isAdmin ? item : !item.adminOnly;
  });

  return (
    <>
      <SHeader>
        {!md && !noSidebar ? (
          <SSidebarToggleBtn onClick={toggleSidebar}>
            <FiMenu />
          </SSidebarToggleBtn>
        ) : null}
        <SLogo
          className="cursor-pointer"
          // onClick={() => {
          //   window.location.href = "/";
          // }}
          href="/"
        >
          {/** Specified width to allow antd `Tour` component to properly point the `Menu` component even before img is rendered. */}
          <img src={logoIcon} alt="sara logo" style={{ width: "48px" }} />
          <img
            className="d-sm-block d-none"
            src={logoText}
            alt="sara logo"
            style={{ width: "100px" }}
          />
        </SLogo>
        {/* 
        {md ? (
          <>
            <Menu />
            <Shortcut />
            <ProgressBar />
            <FunctionButton />
          </>
        ) : null} */}
        {/* <SDivider /> */}
        {/** Notif: sara-message */}
        {/* <NotifSaraMessage /> */}
        {/** Notif: abnormal */}
        <div
          className={`d-flex w-100 align-items-center  gap-3  justify-content-${
            md ? "between" : "end"
          } `}
        >
          {availableItems.map((item) => {
            if (!md) return item.inMobile ? item.element : null;
            return item.element;
          })}

          {md ? <UserAccount /> : <MobileMenu />}
        </div>
      </SHeader>
      <Tour
        open={showTour}
        onClose={() => {
          setShowTour(false);
          setShowTourLS(false);
        }}
        steps={[
          {
            title: "功能選單",
            description: (
              <>
                請透過點擊或滑鼠移動至此處打開選單，以切換至「管理功能」、「排程結果」、「績效報表」和「版次紀錄」等頁面。當滑鼠移開時，選單將自動關閉。
                <br />
                <br />
                部分頁面的側邊欄功能已經轉移至標題欄，其他頁面將陸續進行更新。
              </>
            ),
            target: () =>
              document.getElementById(ID_TOUR_HEADER_MENU) as HTMLElement,
          },
          {
            title: "快速捷徑",
            description:
              "透過快速建立功能，快速配置所需的站點、製程、資源和專案。",
            target: () =>
              document.getElementById(ID_TOUR_HEADER_SHORTCUT) as HTMLElement,
          },
          {
            title: "執行",
            description:
              "請點擊「執行」，展開下拉式選單，以進行排程與發行等動作。",
            target: () =>
              document.getElementById(ID_TOUR_HEADER_ACTION) as HTMLElement,
          },
          {
            title: "系統通知",
            description:
              "當您執行系統資料更新、重排或發行等動作時，系統將提供相關的反饋訊息以顯示執行的狀態和結果。這些反饋訊息可能包括成功完成的提示、錯誤訊息或進度更新。",
            target: () =>
              document.getElementById(ID_TOUR_HEADER_NOTIF) as HTMLElement,
          },
          {
            title: "使用者＆系統設定",
            description:
              "請點擊右上角的使用者圖示，以顯示當前登入的使用者名稱。點擊圖示後，將彈出一個下拉選單，其中包含「系統設定」、「深淺模式切換」和「登出」等選項。",
            target: () =>
              document.getElementById(ID_TOUR_HEADER_PROFILE) as HTMLElement,
          },
        ]}
      />
    </>
  );
};

export default Header;

/** -------------- Styled Components ------------ */
const SSidebarToggleBtn = styled.div`
  font-size: 1.6rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SHeader = styled.div`
  border-bottom: 1px solid var(--bs-light);
  padding-block: 0.4rem;
  padding-inline: 0.5rem 1rem;
  background-color: #293042;
  display: flex;
  align-items: center;
  gap: 1rem;
  position: fixed;
  top: -0.5px;
  left: 0;
  right: 0;
  z-index: 900;
`;

const SLogo = styled.a`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin-right: auto;
  }
`;

const SDivider = styled.div`
  width: 1px;
  height: 25px;
  margin: 0 0.5rem;
  background-color: gray;

  @media (max-width: 768px) {
    display: none;
  }
`;
/* ----------------- Header Items ---------------- */
const headerItems = [
  {
    element: <Menu key={"menu"} />,
    inMobile: false,
    adminOnly: false,
  },
  {
    element: <Shortcut key={"shortcut"} />,
    inMobile: false, //Temp：因為目前功能只有顯示「快速建立」捷徑，而快速建立只有 admin 可以使用，因此若不是 admin 則先隱藏「快速捷徑」按鈕。
    adminOnly: true,
  },
  {
    element: <ProgressBar key={"progressbar"} />,
    inMobile: false,
    adminOnly: true,
  },
  {
    element: <FunctionButton key={"functionBtn"} />,
    inMobile: false,
    adminOnly: true,
  },
  {
    element: <SDivider key="divider" />,
    inMobile: false,
    adminOnly: true,
  },
  {
    element: <NotifSaraMessage key={"notif-sara"} />,
    inMobile: true,
    adminOnly: true,
  },
  {
    element: <NotifAbnormalMessage key={"notif-abnormal"} />,
    inMobile: true,
    adminOnly: true,
  },
];
