export const PATH_TRIAL_HOME = "/trial";

export const PATH_WORKCENTER = "/workcenter";
export const PATH_WORKCENTER_LIST = `${PATH_WORKCENTER}/list`;
export const PATH_WORKCENTER_SCHEDULE = `${PATH_WORKCENTER}/schedule`;
export const PATH_WORKCENTER_COMMIT_WORK = `${PATH_WORKCENTER}/commit-work`;
export const PATH_WORKCENTER_WIP_RECORD = `${PATH_WORKCENTER}/wip-record`;
export const PATH_WORKCENTER_ABNORMAL = `${PATH_WORKCENTER}/abnormal-report`;
export const PATH_WORKCENTER_PREVIEW_SCHEDULE = `${PATH_WORKCENTER}/preview-schedule`;

export const PATH_VERSION = "/versions";
export const PATH_VERSION_INFO = `${PATH_VERSION}/:id`;
export const get_PATH_VERSION_INFO = (id: string) => `${PATH_VERSION}/${id}`;

export const PATH_PROJECT = "/project";
export const PATH_PROJECT_MANAGEMENT_V2 = `${PATH_PROJECT}/management`;
export const PATH_PROJECT_EDIT_JOB = `${PATH_PROJECT}/:id/edit-job`;
export const get_PATH_PROJECT_EDIT_JOB = (id: string | number) =>
  `${PATH_PROJECT}/${id}/edit-job`;
