import { DO_NOT_USE_CACHE_KEY_LIST } from "@/constants/globals";
import logger from "./logger";

export const getCacheKey = ({
  prefix,
  version,
  customKey,
}: {
  prefix: string;
  version?: string;
  customKey?: string;
}) => {
  const VER = version ? `_${version}` : "";
  const URL = window.location.pathname;
  const PARAMS = window.location.search.substring(1)
    ? `_${window.location.search.substring(1)}`
    : "";
  const baseKey = `${prefix}${VER}_${URL}${PARAMS}`;
  return customKey ? `${baseKey}-${customKey}` : baseKey;
};

export const removeExpiredCacheKey = () => {
  const cacheKeyList = DO_NOT_USE_CACHE_KEY_LIST;
  const path = window.location.pathname;
  try {
    cacheKeyList.forEach((cacheKey) => {
      if (cacheKey === "react-table-sorting-v0") {
        localStorage.removeItem(`${cacheKey}-${path}`);
      }
      if (cacheKey === "react-table-width") {
        localStorage.removeItem(`${cacheKey}${path}`);
      }
      if (cacheKey === "react-table-setting") {
        localStorage.removeItem(`${cacheKey}${path}`);
      }
      if (cacheKey === "react-sidebar-collapsed-v0") {
        localStorage.removeItem(cacheKey);
      }
    });
  } catch (e) {
    logger.error(e);
  }
};
