import FunctionButton from "@/components/header/FunctionButton";
import NotifAbnormalMessage from "@/components/header/Notification/NotifAbnormal";
import NotifSaraMessage from "@/components/header/Notification/NotifSaraMessage";
import ProgressBar from "@/components/header/ProgressBar";
import useAuth from "@/hooks/useAuth";
import useTheme from "@/hooks/useTheme";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Dropdown,
  DropdownProps,
  MenuProps,
  Space,
  Tag,
  theme,
  Typography,
} from "antd";
import {
  cloneElement,
  Fragment,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LogoIcon from "../../../assets/images/logo_icon.svg";
import LogoText from "../../../assets/images/logo_text.svg";
import SunIcon from "./SunIcon";

const { useToken } = theme;

const Text = Typography.Text;

const StyledHeader = styled.header`
  padding-block: 0.4rem;
  padding-inline: 0.5rem 1rem;
  background-color: #1e2636;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  position: fixed;
  top: -0.5px;
  left: 0;
  right: 0;
  z-index: 900;
`;

const StyledLogo = styled.a`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-right: auto;
  }
`;

const StyledDivider = styled.div`
  width: 1px;
  height: 25px;
  margin: 0 0.5rem;
  background-color: gray;

  @media (max-width: 768px) {
    display: none;
  }
`;

const TrialVersionTag = styled(Tag)`
  padding: 0.35rem 1rem;
  font-size: 0.9rem;
  font-weight: bold;
  color: #fff;
  border-color: #536286;
  background-color: #384054;
`;

const AvatarText = styled(Text)`
  margin: 0.5rem 1rem;
`;

const InfoText = styled(Text)`
  color: ${({ theme }) => theme.antd.colorTextSecondary};
`;

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const { user, signOut } = useAuth();

  const navigate = useNavigate();

  const { changeTheme, dark } = useTheme();
  const { token } = useToken();

  const contentStyle: React.CSSProperties = useMemo(
    () => ({
      paddingTop: "1rem",
      backgroundColor: token.colorBgElevated,
      borderRadius: token.borderRadiusLG,
      boxShadow: token.colorBgElevated,
    }),
    [token]
  );

  const menuStyle: React.CSSProperties = useMemo(
    () => ({
      boxShadow: "none",
      paddingBottom: "0.5rem",
    }),
    []
  );

  const handleOpenChange: DropdownProps["onOpenChange"] = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setMenuOpen(nextOpen);
    }
  };

  const headerItems = [
    {
      element: (
        // <div style={{ minWidth: "12rem" }}> TODO: remove due to be a global search bar
        <div style={{ width: "100%", textAlign: "end" }}>
          <ProgressBar key={"progressbar"} />
        </div>
      ),
      inMobile: false,
      adminOnly: true,
    },
    {
      element: <FunctionButton key={"functionBtn"} />,
      inMobile: false,
      adminOnly: true,
    },
    {
      element: <StyledDivider key="divider" />,
      inMobile: false,
      adminOnly: true,
    },
    {
      element: <NotifSaraMessage key={"notif-sara"} />,
      inMobile: true,
      adminOnly: true,
    },
    {
      element: <NotifAbnormalMessage key={"notif-abnormal"} />,
      inMobile: true,
      adminOnly: true,
    },
  ];

  const menu: MenuProps["items"] = useMemo(() => {
    return [
      {
        key: "changeTheme",
        icon: <SunIcon />,
        label: dark ? "淺色模式" : "深色模式",
        onClick: changeTheme,
      },
      {
        key: "signout",
        icon: <LogoutOutlined />,
        label: "登出",
        danger: true,
        onClick: signOut,
      },
    ];
  }, [changeTheme, dark, signOut]);

  const dropdownRender = useCallback(
    (menu) => {
      return (
        <Space direction="vertical" style={contentStyle}>
          <AvatarText>
            <Text strong style={{ marginRight: "0.5rem" }}>
              {user?.username}
            </Text>
            <Tag color="yellow">{user?.role}</Tag>
          </AvatarText>
          <AvatarText>
            <InfoText>公司名稱</InfoText> {user?.companyName}
          </AvatarText>
          <AvatarText>
            <InfoText>客戶代碼</InfoText> {user?.customerId}
          </AvatarText>
          <Divider style={{ margin: "0.5rem 0 0" }} />
          {cloneElement(menu as ReactElement, { style: menuStyle })}
        </Space>
      );
    },
    [contentStyle, menuStyle, user]
  );

  return (
    <StyledHeader>
      <StyledLogo onClick={() => navigate("/trial")}>
        <img src={LogoIcon} alt="sara logo" style={{ width: "48px" }} />
        <img src={LogoText} alt="sara text" style={{ width: "100px" }} />
      </StyledLogo>
      <Space>
        <TrialVersionTag>試用版</TrialVersionTag>
      </Space>
      {headerItems.map((item, index) => (
        <Fragment key={index}>{item.element}</Fragment>
      ))}
      <Space>
        <Dropdown
          menu={{ items: menu }}
          trigger={["click"]}
          onOpenChange={handleOpenChange}
          open={menuOpen}
          arrow={{ pointAtCenter: true }}
          placement="bottomRight"
          dropdownRender={dropdownRender}
        >
          <Button
            icon={<UserOutlined style={{ color: "gray" }} />}
            shape="circle"
            style={{ background: "white" }}
          />
        </Dropdown>
      </Space>
    </StyledHeader>
  );
};

export default Header;
