import useAuth from "@/hooks/useAuth";
import NotificationApi from "@/utils/api/notification";
import { Popover } from "antd";
import classNames from "classnames";
import { BiBell } from "react-icons/bi";
import { ID_TOUR_HEADER_NOTIF } from "../../../constants/globals";
import useApi from "../../../hooks/useApi";
import useAppSelector from "../../../hooks/useAppSelector";
import {
  selectProgressBar,
  selectSaraMessage,
} from "../../../redux/slices/notification";
import { EVENT } from "../../../types/notification";
import S from "../Header.module.scss";
import DropdownContent from "./DropdownContent";
import { getBsColorClass_ByLevel, renderMessageCount } from "./funcs";

/** ---------- Code Start ---------- */
const NotifSaraMessage = () => {
  const message = useAppSelector(selectSaraMessage);
  const pgBar = useAppSelector(selectProgressBar);
  const api = useApi(NotificationApi);
  const { isTrial: isTrail } = useAuth();

  const bgBsClass = getBsColorClass_ByLevel(message.level, "bg");
  const textBsClass = getBsColorClass_ByLevel(message.level, "text");

  const toggleRead = () => {
    if (message.data.length > 0) {
      api.read({ event: EVENT.SARA, id: message.data[0].id });
      api.resetLevel({ event: EVENT.SARA });
    }
  };

  const url = `${
    isTrail ? "trial" : ""
  }/notification/history?event=saraMessage`;

  const popoverContent = (
    <DropdownContent
      message={message}
      hrefShowAll={url}
      toggleRead={toggleRead}
    />
  );

  return (
    <Popover trigger="click" content={popoverContent}>
      <button className={S.notifBtn} id={ID_TOUR_HEADER_NOTIF}>
        <BiBell className={textBsClass} />
        <span
          className={classNames(S.notifCount, bgBsClass, {
            [S.blink]: !pgBar.idle,
          })}
        >
          {renderMessageCount(message.count)}
        </span>
      </button>
    </Popover>
  );
};

export default NotifSaraMessage;
