import { useState } from "react";

/** Antd */
import { Divider, Flex, Popover, Space, Typography } from "antd";

/** Constants */
import { ID_TOUR_HEADER_PROFILE } from "../../constants/globals";

/** Hooks */
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import useTheme from "../../hooks/useTheme";

/** Icons */
import { BsGlobeAsiaAustralia, BsMoonStars, BsSun } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { FiLogOut, FiSettings } from "react-icons/fi";

/** Plugins */
import { Link } from "react-router-dom";
import styled from "styled-components";

const Text = Typography.Text;

/** -------------- Code Starts ------------ */
const UserAccount = ({ isMobile }: { isMobile?: boolean }) => {
  const { user, signOut } = useAuth();
  const { changeTheme, dark } = useTheme();
  const { t } = useTranslation("common", { keyPrefix: "header" });
  const [open, setOpen] = useState(false);
  const closePopover = () => setOpen(false);

  const popoverContent = (
    <SAccountContent isMobile={isMobile}>
      <SInfo>
        <span className="name">{user?.username}</span>
        <span className="role">{t(`role.${user?.role}`)}</span>
        <Flex vertical gap={5} style={{ margin: "0.5rem 0 0.75rem" }}>
          <Space size="small">
            <Text strong>{t("companyName")}</Text>
            <Text>{user?.companyName}</Text>
          </Space>
          <Space size="small">
            <Text strong>{t("customerID")}</Text>
            <Text>{user?.customerId}</Text>
          </Space>
        </Flex>
      </SInfo>
      <SSDivider />
      <SList>
        {user?.role === "user" ? null : (
          <li>
            <Link to="/setting/users">
              <FiSettings /> {t("setting")}
            </Link>
          </li>
        )}

        {/* <li>
          <Link to="/setting/help">
            <FiHelpCircle /> 幫助
          </Link>
        </li> */}
        {isMobile ? null : (
          <li onClick={changeTheme}>
            {dark ? <BsSun /> : <BsMoonStars />}
            {dark ? t("lightMode") : t("darkMode")}
          </li>
        )}
        <li>
          <Link to="/language" onClick={closePopover}>
            <BsGlobeAsiaAustralia />
            {t("language")}
          </Link>
        </li>
      </SList>
      {isMobile ? null : <SSDivider />}
      {isMobile ? null : (
        <SList>
          <li onClick={signOut}>
            <FiLogOut /> {t("logout")}
          </li>
        </SList>
      )}
    </SAccountContent>
  );

  if (isMobile) return popoverContent;

  return (
    <SAccount id={ID_TOUR_HEADER_PROFILE}>
      <Popover
        trigger="click"
        content={popoverContent}
        open={open}
        onOpenChange={() => setOpen((p) => !p)}
      >
        <div className="cursor-pointer">
          <FaUserCircle />
        </div>
      </Popover>
    </SAccount>
  );
};

export default UserAccount;

/** -------------- Styled Components ------------ */
const SAccount = styled.div`
  color: #fff;
  font-size: 1.6rem;
  margin-left: 0.5rem;
`;

const SAccountContent = styled.div<{ isMobile: boolean | undefined }>`
  padding: 0.5rem;

  color: var(--bs-dark);
  font-size: ${(props) => (props.isMobile ? "1rem" : "inherit")};

  // for desktop, explicit width is required to avoid element jumping up and down during screen resizing and certain screen resolutions
  width: ${(props) => (props.isMobile ? "100%" : "max-content")};
`;

const SInfo = styled.div`
  .name {
    font-weight: bolder;
  }

  .role {
    font-size: 0.8rem;
    color: #ffffff;
    margin: 0 1rem;
    padding: 0.1rem 0.5rem;
    border-radius: 10rem;
    background-color: #69b1ff; //to be adjusted later
  }

  .number {
    margin-top: 0.5rem;
  }
`;

const SList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & > a {
      width: 100%;
      text-decoration: none;
      color: inherit;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    cursor: pointer;
    &:hover,
    & > a:hover {
      color: ${(props) => props.theme.antd.colorPrimary};
    }
  }
`;

const SSDivider = styled(Divider)`
  margin: 0.5rem 0;
`;
