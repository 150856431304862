import { EXACT_FILTER_BG_DARK_COLOR } from "@/constants/globals";
import { SELECTED_WORKCENTER_COLOR } from "@/constants/resourceList";
import { CheckCircleFilled } from "@ant-design/icons";
import { Flex } from "antd";
import styled from "styled-components";
import { scrollCSS } from "../../styledForm";

export const SCapabilitySection = styled.section`
  width: 100%;
  min-width: 600px;
  margin-bottom: 2.25rem;
`;

export const SSectionTitle = styled.div`
  span:first-of-type {
    margin-right: 0.25rem;
    font-size: 1.5rem;
    color: ${(props) => props.theme.antd.colorTextLabel};
  }

  span:last-of-type {
    font-size: 0.8rem;
    color: ${(props) => props.theme.antd.colorTextSecondary};
  }
`;

export const SList = styled.ul`
  padding: 0;
  margin: 0;
  height: 235px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.antd.colorBorder};
  background-color: ${(props) =>
    props.theme.dark && EXACT_FILTER_BG_DARK_COLOR};
  overflow-y: auto;
  ${scrollCSS}
`;

export const SWorkcenterOption = styled.li<{ $isSelected: boolean }>`
  border-bottom: 1px solid ${(props) => props.theme.antd.colorBorderSecondary};
  padding: 0.5rem 0.75rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${({ theme, $isSelected }) => {
    if (theme.dark) return;
    return $isSelected ? theme.antd.colorBgLayout : theme.antd.colorBgElevated;
  }};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${SELECTED_WORKCENTER_COLOR};
  }
`;

export const SSelectedIcon = styled(CheckCircleFilled)`
  color: ${(props) => props.theme.antd.colorPrimary};
`;

export const SCapabilityOption = styled.li<{ $isSelected: boolean }>`
  padding: 0.2rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--bs-dark);
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s;
  background-color: ${(props) =>
    props.$isSelected ? "#e9c5934e" : "transparent"};

  :hover {
    border: 1px solid var(--bs-dark);
  }
`;

export const EmptyPlaceholder = styled.li`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.antd.colorTextSecondary};
`;

export const EmptyCapabilityPlaceholder = styled(EmptyPlaceholder)`
  height: 12.5rem;
`;

export const SCapabilityTag = styled.span`
  color: ${(props) => props.theme.antd.colorTextLabel};
`;

export const SFlex = styled(Flex)`
  padding: 0.25rem 0.5rem;
  background-color: ${({ theme }) =>
    theme.dark ? theme.antd.colorBgElevated : theme.antd.colorBgLayout};
`;

export const CapabilityTagList = styled.ul`
  padding: 0.5rem;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  overflow-y: auto;
`;
