import { QuestionCircleOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import FeedbackModal from "./FeedbackModal/FeedbackModal";
import useFeedback from "./FeedbackModal/useFeedback";
import usePageState from "./FeedbackModal/usePageState";

const FloatHelper = () => {
  const { open, openModalWith, closeModal } = useFeedback();
  const { page, step } = usePageState();

  return (
    <>
      {/* Remain following code for future use */}
      {/* <FloatButton.Group
        type="primary"
        open={isOpenHelper}
        trigger="click"
        tooltip={isOpenHelper ? "關閉" : "取得協助"}
        style={{ insetInlineEnd: 24 }}
        onClick={() => setIsOpenHelper(!isOpenHelper)}
        onBlur={() => setIsOpenHelper(false)}
        icon={<QuestionCircleOutlined />}
      >
        <FloatButton tooltip="下載操作手冊" />
        <FloatButton
          tooltip="提出問題"
          icon={<CustomerServiceOutlined />}
          onClick={() => openModalWith("快速上線")}
        />
      </FloatButton.Group> */}
      <FloatButton
        type="primary"
        icon={<QuestionCircleOutlined />}
        onClick={() => openModalWith(page, step)}
      />
      <FeedbackModal
        open={open}
        step={step}
        pageTitle={page}
        closeModal={closeModal}
      />
    </>
  );
};

export default FloatHelper;
